<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="body">
      <div class="banner">
        <img src="../assets/faqbg.png" alt="">
        <div class="fixed">
          <div class="title">Instrucciones de Uso de Permisos</div>
        </div>
      </div>
      <div class="content">
        <div class="box">
          <div>&nbsp;</div>
          <div>Por favor, tómese el tiempo necesario para leer con atención y comprender en su totalidad nuestra POLÍTICA DE PRIVACIDAD antes de utilizar nuestros productos y servicios. Esto le permitirá tomar decisiones informadas que considere adecuadas. Nuestra Política de Privacidad está diseñada para proporcionarle claridad sobre los motivos y el modo en que recopilamos, almacenamos, utilizamos y protegemos su información personal. Si desea obtener información más detallada, le invitamos a hacer clic para acceder al contenido completo.</div>
          <div>&nbsp;</div>
          <div>Asimismo, al utilizar esta aplicación, es necesario solicitar los permisos correspondientes de su sistema. Al realizar la primera llamada, le consultaremos mediante una ventana emergente o un mensaje adicional si acepta habilitar estos permisos. Además, tiene la opción de gestionar los permisos pertinentes en la configuración de su sistema.</div>
          <div>&nbsp;</div>
          <div><b class="blue">1. Permisos del Dispositivo:</b> Para llevar a cabo la implementación integral de la detección de seguridad en el entorno de red, el control de riesgos comerciales, la salvaguardia de las cuentas y las medidas antifraude, hemos integrado procedimientos específicos en diversas fases, como el registro/inicio de sesión, la activación de cuotas para productos de préstamo y el proceso de préstamo de dinero. Durante el registro o inicio de sesión, así como en la activación de cuotas y préstamos, se ejecutan verificaciones para asegurar la legitimidad del estado de inicio de sesión y prevenir posibles ataques. Es importante destacar que sus datos son cifrados y transmitidos de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://backend.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
          <div><b class="blue">2. Autoridad de Posicionamiento:</b> Con el objetivo de resguardar la seguridad de su cuenta y llevar a cabo el control de riesgos comerciales, las instituciones financieras definen la zona de acceso, la cual se activa y se verifica durante el proceso de habilitación y endeudamiento del límite de préstamo. Durante estas fases, se implementan medidas de seguridad para garantizar la protección de sus datos, que son cifrados y transmitidos de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://backend.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
          <div><b class="blue">3. Permisos de Cámara:</b> Durante el proceso de reconocimiento facial y la verificación de tarjetas de identificación y bancarias, se pueden utilizar los permisos de la cámara para capturar imágenes en tiempo real y procesar tanto imágenes como videos. Este método se emplea con el propósito de fortalecer la autenticación y la seguridad en el proceso de verificación.</div>
          <div>&nbsp;</div>
          <div><b class="blue">4. Permiso de Calendario:</b> Implementado en situaciones de recordatorio para el pago del día de la factura y alerta de inicio de sesión, se lleva a cabo un llamado en ambas instancias. Este mecanismo se activa con el objetivo de recordar a los usuarios sobre el vencimiento de pagos o proporcionar alertas importantes relacionadas con el inicio de sesión en el sistema.</div>
          <div>&nbsp;</div>
          <div><b class="blue">5. Permisos de SMS:</b> Con el fin de generar informes crediticios, evaluar límites de préstamos, prevenir el fraude y garantizar la seguridad de la cuenta, se realiza un proceso de llamada durante la activación y endeudamiento del límite de préstamo. Únicamente recopilamos mensajes de texto relacionados con asuntos financieros relevantes, que nos proporcionan información crucial para la evaluación de sus deudas y transferencias pendientes, permitiéndonos generar un puntaje crediticio preciso. Es importante destacar que sus datos se cifran y transmiten de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://backend.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
          <div><b class="blue">6. Permiso de Registros de Llamadas:</b> Asegúrese de que la aplicación esté correctamente instalada en su dispositivo y proceda a enviar la verificación dinámica de contraseña. Realizamos un seguimiento de los registros de llamadas de los usuarios, específicamente con el propósito de la verificación dinámica de contraseñas. Es importante mencionar que sus datos se cifran y transmiten de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://backend.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
    <myFoot />
    <!-- <goTop /> -->
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
// import goTop from "@/components/goTop";

export default {
  data() {
    return {
      current: '1'
    };
  },
  created() {
    
  },
  methods: {

  },
  components: {
    myHead,
    myFoot
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 80px;
}
.banner {
  position: relative;

  img {
    width: 100%;
  }
  .fixed {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    text-align: center;
    .title {
      font-size: 63px;
      font-family: YuMin-Demibold, YuMin;
      font-weight: 600;
      color: #333333;
      line-height: 95px;
      letter-spacing: 1px;
    }
    .info {
      font-size: 30px;
      font-family: AvenirNext-Medium, AvenirNext;
      font-weight: 500;
      color: #666666;
      line-height: 30px;
      margin-top: 40px;

    }
  }  
}
.content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0px 100px 0px;

  .box {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
      line-height: 0;
      clear: both;
    }
    .wrapper {
      padding: 0 16px;
    }
    @media screen and (min-width: 450px) {
      .wrapper {
        width: 1050px;
        margin: 0 auto;
      }
    }

    h1 {
      text-align: center;
    }

    .title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      text-align: center;
    }

    .title1 {
      font-weight: bold;
      font-size: 14px;
    }

    dd {
      margin-inline-start: 0;
    }

    .indent {
      text-indent: 2em;
    }

    p,
    div {
      font-size: 16px;
      line-height: 24px;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 300;
      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue,
        Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
        sans-serif;
    }
  }
}
::v-deep .el-collapse-item__header {
  font-size: 20px !important;
  font-weight: bold;
}
</style>
