<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="body">
      <div class="banner">
        <div class="fixed">
          <div>
            <span class="tit bold">Obtenga un desembolso de préstamo rápido con bajo interés</span>
          </div>
          <div class="info">PrestaBien Cash Loan es una plataforma financiera digital. Con IA, métodos de control de riesgos de big data y el uso de nuestro sistema de calificación crediticia de desarrollo propio. Brindamos servicios que son convenientes para el público, con un alto nivel de seguridad, una buena diversificación de los servicios financieros ayuda a desarrollar la inclusión financiera global.</div>
          <div class="btn">
            <a href="#" target="_blank">Descargar ahora</a>
          </div>
        </div>
        <img src="../assets/homebg.png" alt="">

      </div>
      <div class="box1">
        <div class="left">
          <div class="box blue">
            <img src="../assets/icon1.png" alt="">
            <div class="tit">Verificación de crédito</div>
            <div class="info">El sistema comprobará automáticamente la información que proporcione</div>
          </div>
          <div>
            <div class="box">
              <img src="../assets/icon2.png" alt="">
              <div class="tit">Acuerdo</div>
              <div class="info">Después de que se apruebe la solicitud de préstamo, los fondos se depositarán en su cuenta bancaria vinculada</div>
            </div>
            <div class="box">
              <img src="../assets/icon3.png" alt="">
              <div class="tit">Reembolso</div>
              <div class="info">Siga las instrucciones de pago en la aplicación y pague las facturas a tiempo para obtener un buen crédito</div>
            </div>
          </div>
          
        </div>
        <div class="right">
          <div>
            <div class="blue">Features</div>
            <div class="tit">Descargar</div>
            <div class="info">Descargue PrestaBien de la tienda de aplicaciones y complete la aplicación</div>
          </div>
          <div class="btn">
            <a href="#" target="_blank">PLAY</a>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="box3">
          <div class="title">Sobre nosotros</div>
          <div class="info">PrestaBien es una empresa de tecnología financiera que utiliza la tecnología de Internet para implementar su negocio. El proceso de préstamo sin garantía es rápido y fácil porque PrestaBien utiliza una tecnología especial para recopilar, analizar y procesar su préstamo sin garantía dentro de las 24 horas. Con esta tecnología puedes pedir prestado fácilmente en línea a través de la aplicación PrestaBien. Este producto es solo para grupos de personas mayores de 18 años.</div>
        </div>
        <div class="box3 boxx">
          <div class="title">Contácteno</div>
          <div class="info">Si tiene alguna pregunta, póngase en contacto con nuestro servicio de atención al cliente</div>
          <div class="itembox">
            <div class="item">
              <img src="../assets/phone.png" alt="">
              <div class="tit">Teléfono</div>
              <div class="txt">+56996917894</div>
              <div class="txt">Mon - Vie 10am - 6pm</div>
            </div>
            <div class="item">
              <img src="../assets/email.png" alt="">
              <div class="tit">Correo</div>
              <div class="txt">service@prestabien.biz</div>
              <div class="txt">7*24 horas en línea</div>
            </div>
            <div class="item">
              <img src="../assets/whatsapp.png" alt="">
              <div class="tit">WhatsApp</div>
              <div class="txt">+56996917894</div>
              <div class="txt">Mon - Vie 10am - 6pm</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <myFoot />
    <!-- <goTop /> -->
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
// import goTop from "@/components/goTop";

export default {
  data() {
    return {
      current: '1',
      amount: '&5000'
    };
  },
  created() {
    
  },
  methods: {

  },
  components: {
    myHead,
    myFoot
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 80px;
}
.banner {
  width: 100%;
  padding: 60px 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  > img {
    width: 40%;
    height: fit-content;
  }
  .fixed {
    width: 60%;
    text-align: left;
    .tit {
      display: inline-block;
      width: 100%;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;

      &.bold {
        font-size: 50px;
        font-family: YuMin-Demibold, YuMin;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
        letter-spacing: 1px;
        margin-right: 10px;
      }
    }
    .info {
      width: 518px;
      font-size: 20px;
      font-family: AvenirNext-Medium, AvenirNext;
      font-weight: 500;
      color: #8A8A8F;
      line-height: 30px;
      margin-top: 46px;
    }

    .btn {
      margin-top: 21px;
      a {
        display: inline-block;
        width: 235px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #007AFF;
        color: #007AFF;
        // border-radius: 5px;
      }
    }
  }
}
.box1 {
  width: 100%;
  background: url('../assets/homebg2.png') no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  box-sizing: border-box;
  display: flex;
  
  .left {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 50%;
    .box {
      width: 200px;
      margin: 20px 30px 20px 0px;
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.1);
      
      .tit {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #685858;
        margin: 30px 0px;
      }
      .info {
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 24px;
      }

      > img {
        width: 50px;
      }

      &.blue {
        background: #007AFF;
        .tit,
        .info {
          color: #fff;
        }

      }
    }
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    .blue {
      color: #007AFF;
    }
    .tit {
      font-size: 30px;
      font-weight: bold;
      color: #020248;
      line-height: 46px;
      margin: 40px 0px;
    }
    .info {
      width: 60%;
      font-size: 20px;
      font-weight: 400;
      color: #13254A;
      line-height: 30px;
    }
    .btn {
      margin-top: 21px;
      a {
        display: inline-block;
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #007AFF;
        border-radius: 8px;
        border: 1px solid #007AFF;
        color: #fff;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        // border-radius: 5px;
      }
    }
  }
}
.box2 {
  width: 100%;
  background: url('../assets/homebg3.png') no-repeat;
  background-size: contain;
  background-position: top;
}
.box3 {
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  padding-top: 30px;
  &.boxx {
    margin-top: 50px;
  }
  .title {
    font-size: 32px;
    color: #000000;
    text-align: center;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 1px;
  }
  .info {
    width: 70%;
    font-size: 22px;
    font-family: Helvetica;
    color: #666666;
    line-height: 32px;
    text-align: center;
    margin: 0 auto;
  }
  .itembox {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    .item {
      width: 300px;
      padding: 20px 40px 30px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0px 0px 30px 1px #ebeaea;
      background: #fff;
      img {
        width: 50px;
        margin-top: 20px;
      }
      .tit {
        font-size: 18px;
        color: #272727;
        font-weight: bold;
        margin: 20px 0px;
      }
      .txt {
        font-size: 18px;
        color: rgba(39,39,39,0.6);
        line-height: 30px;
        margin-top: 20px;
      }
    }
  }
}
</style>
