
<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="body">
      <div class="banner">
        <img src="../assets/privacybg.png" alt="">
        <div class="fixed">
          <div class="title">Política de Privacidad</div>
          <!-- <div class="info">Protegemos tu privacidad</div> -->
        </div>
      </div>
      <div class="content">
        <div class="box">
          <div>Nos tomamos muy en serio la salvaguarda de la privacidad de nuestros usuarios. Por lo tanto, nuestra Política de Privacidad tiene como objetivo brindarle información detallada sobre cómo gestionamos y protegemos la información y datos personales de aquellos que utilizan nuestra aplicación y/o sitio web, pertenecientes a PrestaBien. Le recomendamos encarecidamente que lea detenidamente y comprenda en su totalidad nuestra Política de Privacidad antes de utilizar nuestros servicios. Al acceder a nuestra Plataforma o hacer uso de cualquier servicio que ofrecemos, y al aceptar los términos descritos en esta Política, confirma su consentimiento y comprensión sobre cómo manejamos la información personal. Valoramos profundamente su privacidad y nos comprometemos a mantener la confidencialidad y seguridad de sus datos. Si tiene alguna pregunta o inquietud con respecto a nuestra Política de Privacidad, no dude en ponerse en contacto con nosotros. Agradecemos la confianza que ha depositado en PrestaBien.</div>
          <div>&nbsp;</div>
          <div class="title1">1.Nuestra Responsabilidad en Relación a los Datos Personales </div>
          <div>&nbsp;</div>
          <div>En PrestaBien, cumplimos de manera estricta con la legislación vigente en lo que concierne a la captura, almacenamiento, divulgación y uso de sus datos personales. Esto abarca aspectos como el acceso, la administración, la transmisión y la eliminación de estos datos.</div>
          <div>&nbsp;</div>
          <div class="title1">2.Solicitudes de Autorización </div>
          <div>&nbsp;</div>
          <div>Con el propósito de mejorar nuestros servicios y brindarle la mejor experiencia posible, solicitamos su consentimiento para la recolección y utilización de ciertos datos personales. Estos datos son gestionados de manera responsable y protegidos a través de procesos seguros de transmisión a nuestros servidores:</div>
          <div>&nbsp;</div>
          <div class="indent">Permisos del Dispositivo: Para llevar a cabo la implementación integral de la detección de seguridad en el entorno de red, el control de riesgos comerciales, la salvaguardia de las cuentas y las medidas antifraude, hemos integrado procedimientos específicos en diversas fases, como el registro/inicio de sesión, la activación de cuotas para productos de préstamo y el proceso de préstamo de dinero. Durante el registro o inicio de sesión, así como en la activación de cuotas y préstamos, se ejecutan verificaciones para asegurar la legitimidad del estado de inicio de sesión y prevenir posibles ataques. Es importante destacar que sus datos son cifrados y transmitidos de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://backend.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
          <div class="indent">Autoridad de Posicionamiento: Con el objetivo de resguardar la seguridad de su cuenta y llevar a cabo el control de riesgos comerciales, las instituciones financieras definen la zona de acceso, la cual se activa y se verifica durante el proceso de habilitación y endeudamiento del límite de préstamo. Durante estas fases, se implementan medidas de seguridad para garantizar la protección de sus datos, que son cifrados y transmitidos de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://backend.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
          <div class="indent">Permisos de Cámara: Durante el proceso de reconocimiento facial y la verificación de tarjetas de identificación y bancarias, se pueden utilizar los permisos de la cámara para capturar imágenes en tiempo real y procesar tanto imágenes como videos. Este método se emplea con el propósito de fortalecer la autenticación y la seguridad en el proceso de verificación.</div>
          <div>&nbsp;</div>
          <div class="indent">Permiso de Calendario: Implementado en situaciones de recordatorio para el pago del día de la factura y alerta de inicio de sesión, se lleva a cabo un llamado en ambas instancias. Este mecanismo se activa con el objetivo de recordar a los usuarios sobre el vencimiento de pagos o proporcionar alertas importantes relacionadas con el inicio de sesión en el sistema.</div>
          <div>&nbsp;</div>
          <div class="indent">Permisos de SMS: Con el fin de generar informes crediticios, evaluar límites de préstamos, prevenir el fraude y garantizar la seguridad de la cuenta, se realiza un proceso de llamada durante la activación y endeudamiento del límite de préstamo. <i><b>Recopilaremos todo su REGISTRO de SMS</b></i>, que nos proporcionan información crucial para la evaluación de sus deudas y transferencias pendientes, permitiéndonos generar un puntaje crediticio preciso. Es importante destacar que sus datos se cifran y transmiten de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://www.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
          <div class="indent">Permiso de Registros de Llamadas: Asegúrese de que la aplicación esté correctamente instalada en su dispositivo y proceda a enviar la verificación dinámica de contraseña. <i><b>Realizamos un seguimiento de todo el historial de llamadas de nuestros usuarios</b></i>, específicamente con el propósito de la verificación dinámica de contraseñas. Es importante mencionar que sus datos se cifran y transmiten de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue;">https://www.prestabien.biz</b>.</div>
          <div>&nbsp;</div>
          <div class="title1">3.Protección de la Información</div>
          <div>&nbsp;</div>
          <div>Aplicamos rigurosas medidas de seguridad físicas, administrativas y técnicas para proteger su información personal. Utilizamos la encriptación para mantener la privacidad de sus datos y aseguramos una transmisión segura a nuestro servidor.</div>
          <div>&nbsp;</div>
          <div class="title1">4.Cookies</div>
          <div>&nbsp;</div>
          <div>Nuestra aplicación puede utilizar cookies con el fin de mejorar nuestros servicios. Usted tiene la opción de aceptar o rechazar estas cookies según su preferencia.</div>
          <div>&nbsp;</div>
          <div class="title1">5.Proveedores de Servicios</div>
          <div>&nbsp;</div>
          <div>Colaboramos con terceros para facilitar y mejorar nuestros servicios. Estos terceros tienen acceso a sus Datos Personales, pero están comprometidos a salvaguardar su privacidad.</div>
          <div>&nbsp;</div>
          <div class="title1">6.Contacto con Nosotros</div>
          <div>&nbsp;</div>
          <div>Si tiene alguna pregunta, comentario o sugerencia relacionada con nuestra Política de Privacidad, le invitamos cordialmente a ponerse en contacto con nosotros. Estamos a su disposición para atender sus inquietudes y proporcionar la información adicional que pueda necesitar. Puede comunicarse con nosotros a través de los siguientes medios:</div>
          <div>&nbsp;</div>
          <div>Correo Electrónico: <b style="color: blue;">service@prestabien.biz</b></div>
          <div>&nbsp;</div>
          <div>Horario de Oficina: De lunes a sábado, de 8:30 a 17:00</div>
          <div>&nbsp;</div>
          <div>Página Web de la Empresa: <b style="color: blue;">https://www.prestabien.biz</b></div>
          <div>&nbsp;</div>
          <div>Esta Política de Privacidad entrará en vigor a partir de su aceptación y estará sujeta a revisiones periódicas. Le recomendamos que la consulte regularmente para mantenerse informado acerca de cómo preservamos y protegemos su privacidad. Nos comprometemos a actualizar y mejorar de manera continua nuestras prácticas de privacidad, buscando garantizar la máxima transparencia y seguridad en el manejo de sus datos personales. Su comprensión y adhesión a esta política son esenciales para asegurar una experiencia confiable y segura al utilizar nuestros servicios en la Plataforma de PrestaBien. Si tiene alguna pregunta o inquietud, no dude en ponerse en contacto con nosotros. Agradecemos su confianza y colaboración en la protección de su privacidad.</div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
    <myFoot />
    <!-- <goTop /> -->
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
// import goTop from "@/components/goTop";

export default {
  data() {
    return {
      current: '1'
    };
  },
  created() {
    
  },
  methods: {

  },
  components: {
    myHead,
    myFoot
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 80px;
}
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }
  .fixed {
    position: absolute;
    left: 80px;
    top: 50%;
    text-align: center;
    .title {
      font-size: 63px;
      font-family: YuMin-Demibold, YuMin;
      font-weight: 600;
      color: #333333;
      line-height: 95px;
      letter-spacing: 1px;
    }
    .info {
      font-size: 30px;
      font-family: AvenirNext-Medium, AvenirNext;
      font-weight: 500;
      color: #666666;
      line-height: 30px;
      margin-top: 40px;
    }
  }
  
}
.content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0px 100px 0px;

  .box {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
      line-height: 0;
      clear: both;
    }
    .wrapper {
      padding: 0 16px;
    }
    @media screen and (min-width: 450px) {
      .wrapper {
        width: 1050px;
        margin: 0 auto;
      }
    }

    h1 {
      text-align: center;
    }

    .title {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      text-align: center;
    }

    .title1 {
      font-weight: bold;
      font-size: 20px;
    }

    .indent {
      text-indent: 2em;
    }

    p,
    div,
    li {
      font-size: 16px;
      line-height: 24px;

      b {
        font-weight: 900;
      }
    }
  }
}
::v-deep .el-collapse-item__header {
    font-size: 20px !important;
    font-weight: bold;
  }
</style>
